@import "palette.scss";
@import "sizes.scss";
$color-white: #fff;
$color-grey: #eceff1;
$color-grey-dark: #808080;
$color-green: #009688;
$color-red: #F44336;
$color-orange: #FF9800;
$color-amber: #FFC107;
$color-yellow: #FFEB3B;
$color-blue-light: #039be5;
$color-blue: #2573b7;
$global-gutter: 16px;
$global-radius: 2px;
$global-box-shadow: 0px 1px 3px rgba(45, 45, 45, 0.2);

.timeline-horizontal {
    position: relative;
    display: block;
    margin: 150px 0px 50px 0px;
    height: 4px;
    background: linear-gradient(45deg, $color-grey, $color-grey-dark);

    &::before,
    &::after {
        content: "";
        position: absolute;
        top: -8px;
        display: block;
        width: 0;
        height: 0;
        border-radius: 10px;
        border: 10px solid $color-grey;
    }

    &::before {
        left: -5px;
    }

    &::after {
        right: -10px;
        border: 10px solid transparent;
        border-right: 0;
        border-left: 20px solid $color-grey-dark;
        border-radius: 3px;
    }

    /* ---- Timeline elements ---- */
    li {
        position: relative;
        top: -60px;
        display: inline-block;
        width: 100px;
        transform: rotate(-45deg);
        font-size: 10px;
    }

    li::before {
        content: "";
        position: absolute;
        top: 3px;
        left: -29px;
        display: block;
        width: 6px;
        height: 6px;
        border: 4px solid #c2c4c6;
        border-radius: 10px;
        background: #eee;
        box-sizing: unset;
    }

    li.item-pending::before,
    li.item-to_receive::before {
        border-color: $color-orange;
    }

    li.item-canceled_by_store::before,
    li.item-canceled::before,
    li.item-expired::before {
        border-color: $color-red;
    }

    li.item-finished::before,
    li.item-confirmed::before,
    li.item-receivable::before,
    li.item-payed::before,
    li.item-reversing::before {
        border-color: $color-green;
    }

    li.item-authorized_by_customer::before,
    li.item-pre_authorized_by_gateway::before,
    li.item-anticipated::before {
        border-color: $color-blue-light;
    }

    .title {
        margin: 0;
        padding: 0;
        overflow: hidden;
        display: inline-block;
        line-height: 1;
        text-overflow: ellipsis;
    }

    .subtitle {
        font-size: 10px;
        opacity: 0.5;
    }

    /* ---- Details ---- */
    .details {
        display: none;
        position: absolute;
        left: -80px;
        top: 25px;
        padding: 10px;
        border-radius: 3px;
        box-shadow: $global-box-shadow;
        transform: rotate(45deg);
        font: 12px arial;
        background: #fff;
    }

    /* ---- Hover effects ---- */
    li:hover {
        cursor: pointer;
        color: #28e;
    }

    li:hover::before {
        top: 1px;
        left: -31px;
        width: 8px;
        height: 8px;
        border-width: 5px;
        border-color: #28e;
    }

    li:hover .details {
        display: block;
        color: #444;
        width: 60px;
        font-size: 11px;
        padding: 8px;
    }
}

.timeline-horizontal {
    li {
        top: -62px;

        .subtitle {
            font-size: 8px;
        }

        &.item-anticipated {
            .paymentDate {
                text-decoration: line-through;
            }
        }
    }
}

.canceled {
    text-decoration: line-through;
}