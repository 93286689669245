.store {
    &-list {
        font-size: 0.9em;
        &__row {
            background-color: #F6F6F6;

            .d-grid {
                display: grid;
                grid-template-columns: auto 1fr;

                & > div {
                    word-wrap: break-word;
                    overflow: hidden;
                }
            }
        }
        &__thumb {
            figure {
                width: 68px;
                height: 68px;
                padding: 0;
                margin: 0;
                border-radius: 100%;
                background-color: #90a4ae;
                overflow: hidden;
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                    width: auto;
                    max-width: 100%;
                    height: auto;
                    max-height: 100%;
                }
            }

            .switch {
                width: 3.5em;
                height: 1.8em;
                margin: 0 auto;
                display: flex;
                border-radius: 2em;
                cursor: pointer;

                &:before {
                    content: " ";
                    display: block;
                    width: 1.4em;
                    height: 1.4em;
                    margin: 0.2em;
                    background-color: #fff;
                    border-radius: 100%;
                }

                &.active{
                    justify-content: flex-end;
                    background-color: #a4cd39;
                }
                &.inactive{
                    background-color: #ee4250;
                }
            }
        }
    }
    &-form {
        &__breadcrumb {
            button {
                border: none;
                background-color: transparent;

                .icon {
                    width: 2.2em;
                    height: 2.2em;
                    border: 2px solid #CCC;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 1.4em;

                    i {
                        padding: 0;
                        color: #999;
                    }
                }
                .name {
                    color: #666;
                }
                &.active {
                    .icon {
                        border-color: #f9d537;
                        background-color: #f9d537;
                        
                        i {
                            color: #2163a2;
                        }
                    }
                    .name {
                        font-weight: bold;
                    }
                    &.done {
                        .icon {
                            border-color: #f9d537;
                        }
                    }
                }
                &.done {
                    .icon {
                        border-color: #a4cd39;
                    }
                }
            }
        }
        
        &__address {
            &__map {
                padding: 2em 0 1.2em;
                iframe {
                    height: 100%;
                    border: 1px solid #e4e7ea;
                    border-radius: 5px;
                }
            }
        }

        &__images {
            &__card {
                border: 2px dashed #CCC;
                background-color: #eeeff0;
            
                &__figurebox {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 12em;

                    figure {
                        margin: 0;
                        background-color: #90a4ae;
                        border: 1px solid #F2F2F2;
                        border-radius: 3px;
                        box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.2);

                        img {
                            width: auto;
                            height: auto;
                            max-width: 100%;
                            max-height: 100%;
                        }
                        .waiting {
                            color: rgba(0, 0, 0, 0.2);
                        }
                    }
                }
                .input-file {
                    display: none;
                }
                .btn-info {
                    color: #FFF;
                }
            }
        }

        &__images__card__figurebox, &__resume {
            .figure {                       
                &__background {
                    width: 254px;
                    height: 157px;

                    .frame{
                        width: 100%;
                        height: 40px;
                        display: block;
                        background-color: #fff;
                    }
                    .img-box {
                        width: 100%;
                        height: 115px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                    .waiting {
                        font-size: 3.5em;
                    }
                }
                &__photo {
                    width: 126px;
                    height: 156px;

                    .frame{
                        width: 100%;
                        height: 77px;
                        display: block;
                        background-color: #fff;
                    }
                    .img-box {
                        width: 100%;
                        height: 77px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                    .waiting {
                        font-size: 2.5em;
                    }
                }
                &__listImage {
                    .frame {
                        display: flex;
                        
                        img, i {
                            width: 58px;
                            height: 58px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            border: 6px solid #FFF;
                        }
                        .text {
                            width: 200px;
                            background-color: #fff;
                        }
                        .waiting {
                            font-size: 1.5em;
                        }
                    }
                }
            }
        }
        &__resume {
            .figure {                       
                &__background, &__photo {
                    img {
                        width: auto;
                        height: auto;
                        max-width: 100%;
                        max-height: 100%;
                    }
                }
                &__background, &__photo, &__listImage {
                    box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.3);
                    
                    .img-box {
                        background-color: #90a4ae;
                    }
                }
                &__listImage {
                    width: 260px;
                }
            }
        }
    }
}
