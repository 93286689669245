@import "palette.scss";
@import "sizes.scss";

.payments-daily-summary {
  display: flex;
  position: relative;
  flex-direction: column;
  padding-left: $size-30;
  padding-right: $size-30;
  background-color: $white;
  height: 100%;

  .title {
    margin-top: 2.9em;
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    div {
      font-size: $size-18;
      font-weight: 500;
      color: $medium-grey;
    }
    .circle {
      border-radius: 50%;
      height: 14px;
      width: 14px;
      margin-left: 1em;
      &__green {
        background-color: $green;
      }
      &__red {
        background-color: $red;
      }
      &__yellow {
        background-color: $yellow;
      }
      &__none {
        background-color: $grey-font2;
      }
    }
  }
  .body {
    margin-bottom: 20px;
    .line {
      height: 41px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding-left: $size-19;
      padding-right: $size-19;
      background-color: $light-grey;
      margin-bottom: $size-10;
      border-radius: $size-3;
      .label {
        font-size: $size-14;
        color: $grey-font2;
      }
      .value {
        font-size: $size-19;
        &__gray {
          color: $dark-grey;
        }
        &__red {
          color: $red;
        }
        &__orange {
          color: $orange;
        }
        &__green {
          color: $green;
        }
      }
      &__total {
        background-color: $light-grey3;
        .label {
          display: flex;
          align-items: flex-end;
          div:last-child {
            margin-left: $size-3;
            font-size: $size-13;
          }
          div:first-child {
            color: $dark-grey;
            font-size: $size-18;
            font-weight: bold;
          }
        }
      }
    }
  }

  .footer {
    color: $dark-grey;
    letter-spacing: 0.5px;
    .label {
      font-size: $size-13;
      margin-bottom: 12px;
    }
    .text {
      font-size: $size-12;
      font-weight: 200;
    }
  }
}

.payments-daily-summary__nodata {
  justify-content: center;
  position: relative;
  right: 0;
  top: 0;
  .text {
    padding-left: $size-50;
    padding-right: $size-50;
    text-align: center;
    font-size: $size-18;
    color: $grey-font2;
    font-weight: 300;
  }
}
