@font-face {
  font-family: "Roboto Regular";
  font-style: normal;
  font-weight: 400;
  src: url("fonts/Roboto-Regular.ttf");
  src: local("Open Sans"), local("OpenSans"), url("fonts/Roboto-Regular.ttf") format("truetype");
}

.body {
  font-family: "Roboto Regular" !important;
  font-family: "adobe-clean", sans-serif !important;
}

.app-screens {
  background-color: #fbfbfb !important;
  flex: 1 !important;
  display: flex !important;

  .inputs-card-data {
    height: auto !important;
    font-size: 12px !important;
    padding: 0 0 9px 0 !important;
    border: none !important;
    border-bottom: 1px solid rgba(64, 65, 67, 0.45) !important;
    border-radius: 0 !important;
    color: #404143 !important;
  }

  select {
    font-size: 14px !important;
    border: 1px solid rgba(64, 65, 67, 0.45) !important;
    border-radius: 8px !important;
    color: #404143 !important;
  }

  .input-autocomplete {
    font-size: 14px !important;
    border: 1px solid rgba(64, 65, 67, 0.45) !important;
    border-radius: 8px !important;
    color: #404143 !important;
    height: auto !important;
    padding: 4px 5px 4px 36px !important;
    background-color: #ffffff !important;
  
    &:focus-visible {
      outline: none !important;
    }
  }
  
  .container-options {
    position: absolute !important;
    top: 33px !important;

    label {
      color: #404143;
      margin: 0;
    }
  
    .container-banks {
      max-height: 96px !important;
      padding: 5px 0px !important;
      border: 1px solid rgba(221, 221, 221, 0.6) !important;
      border-radius: 2px !important;
      overflow-y: scroll !important;

      &::-webkit-scrollbar {
        width: 5px;
      }
       
      &::-webkit-scrollbar-track {
        background-color: rgba(221, 221, 221, 0.6);
      }
       
      &::-webkit-scrollbar-thumb {
        background-color: rgba(88, 89, 91, 0.38);
      }
  
      .option {
        font-size: 10px !important;
        padding: 5px 16px !important;
        color: #404143 !important;
      }
    }
  }

  .tab-information {
    border-color: rgba(221, 221, 221, 0.6) !important;
  }
}

.font-xm {
  font-size: 0.75rem !important;
}

.font-xxs {
  font-size: 0.625rem !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.text-gray {
  color: #535353 !important;
}

.card {
  border-color: rgba(221, 221, 221, 0.6) !important;
}

.card-payment {
  background-color: #fbfbfb !important;
}

.btn-primary {
  color: #fff;
  background-color: #8cc740;
  border-color: #8cc740;
}

.btn-primary:hover {
  color: #fff;
  background-color: #7aad38;
  border-color: #7ba93f;
}

.btn-primary:focus,
.btn-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem #9be89e;
}

.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem #9be89e;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #8cc740;
  border-color: #8cc740;
}

.cookie-term {
  position: fixed;
  top: 25px;
  width: 60%;
  left: 20%;
  right: 20%;
  background-color: white;
  padding: 20px;
  z-index: 9;
  border-radius: 5px;
  box-shadow: 2px 2px #888888;
  .title {
    font-size: 18px;
    line-height: 50px;
    font-weight: 700;
  }
  .btn {
    font-size: 20px;
  }
}

.loading-balls svg {
  height: 90px;
  width: 240px;
  margin-top: -31px;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.btn:not(:disabled):not(.disabled) {
  margin: 10px;
}

.header-fixed .app-header {
  padding-right: 10px;
}

.float-loading {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(51, 51, 51, 0.7);
  z-index: 99999;

  .loading {
    position: fixed;
    left: 50%;
    top: 35%;

    span {
      color: #ffffff;
      font-weight: bold;
      font-size: 15px;
    }
  }
}

div.card-body,
div.card-header {
  i {
    padding-right: 5px;
  }
}

.btn.disabled,
.btn:disabled {
  margin: 10px;
  cursor: not-allowed;
}

table > tbody > tr > td > img,
div.rt-table > div.rt-tbody > div > div > div > img {
  height: 35px;
}

.badge {
  font-size: 95%;
}

span[id*="EditButton"],
span[id*="DetailsButton"],
span[id*="DetailButton"],
span[id*="InstallmentButton"],
span[id*="SearchButton"],
i[id*="CopyButton"],
i[id*="SearchButton"] {
  cursor: pointer;
}

.position-check {
  padding-left: 20px;
}

.no-border {
  border: none;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  div {
    border-color: rgba(0, 0, 0, 0.1);
  }
}

.ReactTable .table-striped .rt-tbody .rt-tr-group:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.ReactTable .rt-thead .rt-tr {
  text-align: left;
  padding: 0.75rem;
  vertical-align: bottom;
  color: #23282c;
  font-weight: 700;
}

.ReactTable .rt-tfoot .rt-tr {
  text-align: left;
  vertical-align: bottom;
  color: #23282c;
  font-weight: 700;
}

.ReactTable .rt-td {
  padding: 0.75rem;
  vertical-align: bottom;
  border-top: 1px solid #c8ced3;
}

.ReactTable .rt-thead .rt-th,
.ReactTable .rt-thead .rt-td,
.ReactTable .rt-tfoot .rt-th,
.ReactTable .rt-tfoot .rt-td {
  border-right: 0px;
}

.table-index .rt-thead .rt-th:first-child,
.table-index .rt-thead .rt-td:first-child,
.table-index .rt-thead .rt-th:last-child,
.table-index .rt-thead .rt-td:last-child,
.table-button .rt-thead .rt-th:last-child,
.table-button .rt-thead .rt-td:last-child,
.table-index .rt-tfoot .rt-th:first-child,
.table-index .rt-tfoot .rt-td:first-child,
.table-index .rt-tfoot .rt-th:last-child,
.table-index .rt-tfoot .rt-td:last-child,
.table-button .rt-tfoot .rt-th:last-child,
.table-button .rt-tfoot .rt-td:last-child {
  flex: 50 0 auto !important;
  width: 10px !important;
}

.table-index .rt-tbody .rt-td:first-child,
.table-index .rt-tbody .rt-td:last-child,
.table-button .rt-tbody .rt-td:last-child {
  flex: 50 0 auto !important;
  width: 10px !important;
}

.ReactTable .rt-noData {
  top: 68%;
}

div.rt-table input[type="checkbox"] {
  margin-left: 20px;
}

.ReactTable .rt-thead.-filters input,
.ReactTable .rt-thead.-filters select {
  width: 100%;
}

div.transfer-confirm > div > div > div {
  float: left;
}

.border-bottom-title > div > div > div {
  float: left;
  border-bottom: 1px double #ddd;
  border-bottom-width: medium;
}

div.transfer-confirm > div .border-top-title-total {
  border-top: 2px solid #ddd;
}

.transfer-confirm-total {
  margin-top: 10px !important;
}

.search-button {
  margin: 0 0 0 0 !important;
}

.order {
  .title {
    font-weight: 800;
  }

  .sub-title {
    font-size: 11px;
  }

  i.fa-copy {
    margin-top: 5px;
  }

  .itens {
    div {
      float: left;
    }
  }

  .total-value {
    font-size: 18px;
    font-weight: 500;
  }

  .store {
    img {
      width: 50px;
      height: 50px;
      border-radius: 50px;
      float: left;
      margin: 10px 10px 0px 0px;
    }

    .fantasy-name {
      color: #039be5;
      text-decoration: none;
    }

    span {
      font-size: 12px;
    }
  }
}

.card-box {
  label {
    display: block;
  }
}

.identifier-environment {
  width: 100% !important;
  height: 35px !important;
  background-color: #429f4e !important;
  color: #ffffff !important;
  font-weight: 600 !important;
  margin: 0 !important;
  display: flex !important;
  align-items: center !important;
  align-content: center !important;
  position: absolute !important;
  top: 126px !important;
  z-index: 1 !important;

  button {
    height: fit-content !important;
  }
}

.container-identifier {
  width: 100% !important;
  height: 35px !important;
}

footer {
  margin-top: auto;
  height: 72px;
  bottom: 0;
  overflow: hidden;
  border-top: 1px solid #dddddd;

  a {
    color: #58595b;

    &:hover {
      text-decoration: none;
    }
  }
}

.version {
  position: absolute;
  height: auto !important;
  right: 10px;
  opacity: 0.3;
  margin-top: -25px;
}
