@import "palette.scss";

.loading-shade {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 10px;
  left: 0;
  opacity: 1;
  transition: opacity 0s;
  background-color: white;
  display: flex;
  z-index: 10;
  align-items: center;
  justify-content: center;
  &__hidden {
    transition: opacity 1s ease-in;
    pointer-events: none;
    opacity: 0;
  }

  .loading__column {
    top: 50%;
    left: 45%;

    &--bar {
      display: inline-block;
      margin: 0.2em;
      width: 0.3em;
      height: 2em;
      border-radius: 4px;
      animation: loading 1s ease-in-out infinite;
      background-color: $grey-font2;

      &:nth-child(1) {
        animation-delay: 0;
      }

      &:nth-child(2) {
        animation-delay: 0.09s;
      }

      &:nth-child(3) {
        animation-delay: .18s;
      }

      &:nth-child(4) {
        animation-delay: .27s;
      }
    }

    @keyframes loading {
      0% {
        transform: scale(1);
      }
      20% {
        transform: scale(1, 2.2);
      }
      40% {
        transform: scale(1);
      }
    }

  }


  .loading__bar {
    height: 4px;
    width: 100%;
    position: relative;
    overflow: hidden;
    background-color: #ddd;
    &:before{
      display: block;
      position: absolute;
      content: "";
      left: -200px;
      width: 200px;
      height: 4px;
      background-color: $grey-font;
      animation: loading-bar 4s linear infinite;
    }
    @keyframes loading-bar {

      from {left: -200px; width: 30%;}
        50% {width: 30%;}
        70% {width: 70%;}
        80% { left: 50%;}
        95% {left: 120%;}
        to {left: 100%;}
    }
  }
}