@media only screen and (max-width: 600px) {
  #ssl-mobile {
    display: block !important;
  }
  #ssl-web {
    display: none;
  }

  #root
    > div
    > div:nth-child(2)
    > div.app.back-logo.flex-row.align-items-center
    > div
    > div
    > div.mb-3.col-md-12
    > div
    > div
    > div
    > div.float-left.col-md-3
    > img {
    margin-top: 15px;
  }

  #root
    > div
    > div:nth-child(2)
    > div.app.back-logo.flex-row.align-items-center
    > div
    > div
    > div.col-md-7
    > div
    > div
    > div:nth-child(1)
    > div:nth-child(1) {
    label > strong {
      font-size: 18px !important;
    }

    img {
      position: relative !important;
      height: 45px !important;
      right: -20% !important;
    }
  }

  #root
    > div
    > div:nth-child(2)
    > div.app.back-logo.flex-row.align-items-center
    > div
    > div
    > div.col-md-7
    > div
    > div
    > div:nth-child(1)
    > div.card-body
    > form
    > div {
    .col-7,
    .col-5 {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

  #root
    > div
    > div:nth-child(2)
    > div.app.back-logo.flex-row.align-items-center
    > div
    > div
    > div.col-md-7
    > div
    > div
    > div:nth-child(1)
    > div.card-body
    > form
    > div
    > div.float-left.col-5
    > div
    > button {
    padding: 0px !important;
    width: 100% !important;
  }

  .app.back-logo button {
    padding: 0px !important;
    width: 100% !important;
  }
}
