$black: #000;
$white: #fff;
$grey: #ccc;
$light-grey: #f8f8f8;
$light-grey2: #a2a1a1;
$light-grey3: #dbdbdb;
$light-grey4: #f4f4f4;
$medium-grey: #616161;
$dark-grey: #666;
$grey-font: #9b9b9b;
$grey-font2: #999;
$grey-font3: #333;
$grey-border: #e0e0e0;
$grey-bar: #e7e4e6;
$red-background: #fdd4d7;
$red: #ee4250;
$green: #a4cd39;
$yellow: #f9d537;
$orange: #f5a623;
$orange-background: #ffe9c4;
$blue: #2163a2;

$pattern-gradient: linear-gradient(
  to left,
  $blue,
  $green 31%,
  $orange 65%,
  $red
);
$pattern-gradient-yellow: linear-gradient(
  to left,
  #ffdf51,
  $yellow
);
$pattern-gradient-blue: linear-gradient(
  to left,
  #2e79c1,
  $blue
);
